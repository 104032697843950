@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 40vw;
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.klick-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 80vw;
  /* border: 2px solid rgb(159, 12, 22); */
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.info-item {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-title {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-img {
  border-radius: 2em;
  border: 2px solid rgb(159, 12, 22);
  object-fit: contain;
  max-height: 350px;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.info-img-content {
  object-fit: contain;
  /* max-height: 350px; */
  height: 80%;
  width: 80%;
}

.my-notes {
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
}

@media (max-width:580px)  { 
  .MuiDialog-paperWidthLg {
    width: 100% !important;
    margin: 5px !important;
  }
}


/* .modalWrap {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 0;
  position: relative;

  @media only screen and (orientation: portrait) {
    min-width: 200px;
    overflow-y: auto;
  }
} */

/* .modalWrap .closeModalIcon {
  color: black;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: white;
} */

.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 40vw;
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.klick-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 80vw;
  /* border: 2px solid rgb(159, 12, 22); */
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.info-item {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-title {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-img {
  border-radius: 2em;
  border: 2px solid rgb(159, 12, 22);
  object-fit: contain;
  max-height: 350px;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.info-img-content {
  object-fit: contain;
  /* max-height: 350px; */
  height: 80%;
  width: 80%;
}

.my-notes {
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
}

@media (max-width:580px)  { 
  .MuiDialog-paperWidthLg {
    width: 100% !important;
    margin: 5px !important;
  }
}


/* .modalWrap {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 0;
  position: relative;

  @media only screen and (orientation: portrait) {
    min-width: 200px;
    overflow-y: auto;
  }
} */

/* .modalWrap .closeModalIcon {
  color: black;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: white;
} */

/*Chatbot specific CSS from here*/
.chat-container {
  position: fixed; 
  bottom: 50px;
  display: flex;
  flex-direction: column;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000; 
  background-color: #ffffff; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  width: 100%; /* Ensure full width of container */
  box-sizing: border-box; /* Include padding in width calculations */
  border-bottom: 1px solid #ccc; /* Optional: adds a subtle divider */
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
  margin-left: 10px; /* Space between title and button */
}

.close-button:hover {
  color: #ff5e5e;
}

.message-container {
  padding: 10px;
  overflow-y: auto;
  height: 300px; /* Adjust height to ensure it doesn't push content out */
}

.user-message, .bot-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.bot-message {
  align-self: flex-start;
  background-color: #e9ecef;
}

.input-container {
  display: flex;
  padding: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.input-container button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

body {
  background-color: rgb(232, 232, 243) ;
  font-size: 16px;
  font-family: Arial;
}

#root #top {
  display: grid;
  grid-template: repeat(10, 120px) / repeat(6, 1fr) ;
  grid-gap: 3px;
  margin-top: 0px;
  border: 0px black solid;
  align-content: left;
  grid-auto-rows: 120px;

  justify-items: stretch;
  justify-content: stretch;
  align-items: start;
  align-content: start;
}

#root h1 {
  display: grid;
  grid-area: 1 / 2 / 2 /  6  ;
  text-align: center;
}

#root img {
  grid-area:  1 / 1 / 2 / 2;
  justify-content: end;
}

 #root div #data {
  grid-area: 1 / 2 / 11 / 7;
  display: block;
 }

 #root table {
  height: auto;
  width: 100%;
  display: inline-block;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  border: 0px solid rgb(175, 62, 175);
 background-color: white;
}

th, td {
  padding: 12px;
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  border: 0px solid rgba(1, 2, 14, 0.822);
  width: auto;
}


.tableValueCenter {
  text-align: center;
}

.tableValueRight {
  text-align: right;
}


.tableDateTime {
  text-align: center;
  min-width: 7em;
}

nav ul {
  padding: 0;
  list-style-type: none;
  flex: 3 1;
  display: block;

}

nav li {
  display: block;
  text-align: left;
  border: 1px solid black;
  border-collapse: collapse;
  background-color: rgb(255, 211, 211);
  padding: 10px;
  flex: 1 1;
}

nav #item:hover {
  background-color: lemonchiffon;
}

button {
  display: flex;
  background-color: lightgoldenrodyellow;
  padding: 10px;
}

body {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/Diver_photographing_humpback_whale.101a035c.jpg);
    background-position: center;
    background-size: cover;
  /* background-image: url("/Diver_photographing_humpback_whale.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

body.external {
   background: url(/static/media/Diver_photographing_humpback_whale.101a035c.jpg);
   background-position: center;
   background-size: cover;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.browser-check {
  background: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html {
  height: 100%;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body{
  padding-right: 0px !important;
}

a {
  text-decoration: none;
}

[data-test-id="CircularProgressbarWithChildren"] {
  margin-top: 30px;
  max-width: 300px;
}
.mainWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modalWrap {
  padding: 24px;
  /* min-height: 550px; */
}
.userProfileModal .modalWrap {
  padding: 24px;
  max-height: 450px;
}
.newMeetupHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.newMeetupHeader h3 {
  margin: 0;
}
.newMeetupHeader .modalHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.newMeetupHeader .modalHeaderTitle span {
  margin-left: 21px;
}

.input-wrap .chatDurationSelect {
  box-sizing: border-box;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #f4f2f2;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
  padding-left: 15.5px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-wrap .chatDurationSelect::before {
  border-bottom: none;
}
.input-wrap .chatDurationSelect > div {
  padding: 0;
}
.MuiToolbar-root {
  background-color: #dc1448 !important;
}
.MuiPickersDay-current {
  background-color: #dc1448 !important;
}
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #dc1448 !important;
}
.MuiPickersClockPointer-pointer {
  background-color: #dc1448 !important;
}
.MuiPickersClock-pin {
  background-color: #dc1448 !important;
}
.MuiPickersClockPointer-noPoint {
  background-color: #dc1448 !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #dc1448 !important;
}

.countdown-modal .MuiPaper-elevation24 {
  box-shadow: none;
  background-color: initial;
}
.countdown-modal .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9);
}

button{
  cursor: pointer;
}

.orientationNotice {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orientationNotice p {
    text-align: center;
    max-width: 50%;
    font-weight: 700;
    color: white;
    font-size: 25px;
}
.portraitOrientation {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.landscapeOrientation {
  height: 100%;
}
.error-message-wrap {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-message {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  width: 70%;;
}
