body {
  background-color: rgb(232, 232, 243) ;
  font-size: 16px;
  font-family: Arial;
}

#root #top {
  display: grid;
  grid-template: repeat(10, 120px) / repeat(6, 1fr) ;
  grid-gap: 3px;
  margin-top: 0px;
  border: 0px black solid;
  align-content: left;
  grid-auto-rows: 120px;

  justify-items: stretch;
  justify-content: stretch;
  align-items: start;
  align-content: start;
}

#root h1 {
  display: grid;
  grid-area: 1 / 2 / 2 /  6  ;
  text-align: center;
}

#root img {
  grid-area:  1 / 1 / 2 / 2;
  justify-content: end;
}

 #root div #data {
  grid-area: 1 / 2 / 11 / 7;
  display: block;
 }

 #root table {
  height: auto;
  width: 100%;
  display: inline-block;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  border: 0px solid rgb(175, 62, 175);
 background-color: white;
}

th, td {
  padding: 12px;
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  border: 0px solid rgba(1, 2, 14, 0.822);
  width: auto;
}


.tableValueCenter {
  text-align: center;
}

.tableValueRight {
  text-align: right;
}


.tableDateTime {
  text-align: center;
  min-width: 7em;
}

nav ul {
  padding: 0;
  list-style-type: none;
  flex: 3;
  display: block;

}

nav li {
  display: block;
  text-align: left;
  border: 1px solid black;
  border-collapse: collapse;
  background-color: rgb(255, 211, 211);
  padding: 10px;
  flex: 1;
}

nav #item:hover {
  background-color: lemonchiffon;
}

button {
  display: flex;
  background-color: lightgoldenrodyellow;
  padding: 10px;
}