
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

body {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("assets/images/Diver_photographing_humpback_whale.jpg");
    background-position: center;
    background-size: cover;
  /* background-image: url("/Diver_photographing_humpback_whale.jpg"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

body.external {
   background: url("assets/images/Diver_photographing_humpback_whale.jpg");
   background-position: center;
   background-size: cover;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.browser-check {
  background: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html {
  height: 100%;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body{
  padding-right: 0px !important;
}

a {
  text-decoration: none;
}

[data-test-id="CircularProgressbarWithChildren"] {
  margin-top: 30px;
  max-width: 300px;
}
.mainWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modalWrap {
  padding: 24px;
  /* min-height: 550px; */
}
.userProfileModal .modalWrap {
  padding: 24px;
  max-height: 450px;
}
.newMeetupHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.newMeetupHeader h3 {
  margin: 0;
}
.newMeetupHeader .modalHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.newMeetupHeader .modalHeaderTitle span {
  margin-left: 21px;
}

.input-wrap .chatDurationSelect {
  box-sizing: border-box;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #f4f2f2;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
  padding-left: 15.5px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-wrap .chatDurationSelect::before {
  border-bottom: none;
}
.input-wrap .chatDurationSelect > div {
  padding: 0;
}
.MuiToolbar-root {
  background-color: #dc1448 !important;
}
.MuiPickersDay-current {
  background-color: #dc1448 !important;
}
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #dc1448 !important;
}
.MuiPickersClockPointer-pointer {
  background-color: #dc1448 !important;
}
.MuiPickersClock-pin {
  background-color: #dc1448 !important;
}
.MuiPickersClockPointer-noPoint {
  background-color: #dc1448 !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #dc1448 !important;
}

.countdown-modal .MuiPaper-elevation24 {
  box-shadow: none;
  background-color: initial;
}
.countdown-modal .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9);
}

button{
  cursor: pointer;
}

.orientationNotice {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orientationNotice p {
    text-align: center;
    max-width: 50%;
    font-weight: 700;
    color: white;
    font-size: 25px;
}
.portraitOrientation {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.landscapeOrientation {
  height: 100%;
}
.error-message-wrap {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-message {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  width: 70%;;
}