.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 40vw;
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.klick-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  float: left;
  width: 80vw;
  /* border: 2px solid rgb(159, 12, 22); */
  /* padding-top: 20px; */
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.info-item {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-title {
  /*width: 15vw;*/
  /*height: 5vh;*/
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid rgb(159, 12, 22);
  align-self: center;
  background-color: #ffffff;
  color: black;
  border-radius: 2em;
}

.info-img {
  border-radius: 2em;
  border: 2px solid rgb(159, 12, 22);
  object-fit: contain;
  max-height: 350px;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.info-img-content {
  object-fit: contain;
  /* max-height: 350px; */
  height: 80%;
  width: 80%;
}

.my-notes {
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
}

@media (max-width:580px)  { 
  .MuiDialog-paperWidthLg {
    width: 100% !important;
    margin: 5px !important;
  }
}


/* .modalWrap {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 0;
  position: relative;

  @media only screen and (orientation: portrait) {
    min-width: 200px;
    overflow-y: auto;
  }
} */

/* .modalWrap .closeModalIcon {
  color: black;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: white;
} */

/*Chatbot specific CSS from here*/
.chat-container {
  position: fixed; 
  bottom: 50px;
  display: flex;
  flex-direction: column;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000; 
  background-color: #ffffff; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  width: 100%; /* Ensure full width of container */
  box-sizing: border-box; /* Include padding in width calculations */
  border-bottom: 1px solid #ccc; /* Optional: adds a subtle divider */
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
  margin-left: 10px; /* Space between title and button */
}

.close-button:hover {
  color: #ff5e5e;
}

.message-container {
  padding: 10px;
  overflow-y: auto;
  height: 300px; /* Adjust height to ensure it doesn't push content out */
}

.user-message, .bot-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.bot-message {
  align-self: flex-start;
  background-color: #e9ecef;
}

.input-container {
  display: flex;
  padding: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.input-container button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
